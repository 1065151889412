import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import InstrumentsList from './InstrumentsList/InstrumentsList'
import InstrumentsManager from './InstrumentsManager/InstrumentsManager'

function Routes() {
  return (
    <>
      <Route path="/pages/instruments" exact component={InstrumentsList} />
      <Route
        path="/pages/instruments/add"
        exact
        component={InstrumentsManager}
      />
      <Route
        path="/pages/instruments/edit/:id"
        exact
        component={InstrumentsManager}
      />
    </>
  )
}

export default Routes
