import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './NaukList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onFeedDelete from '../NaukManager/functions/deleteFeed'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'
import parse from 'html-react-parser'

function NaukList({ id, title, ...router }) {
  const [feedCatalog, setFeedCatalog] = useState(null)
  const [deleteFeed, setDeleteFeed] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection(id).onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ id: d.id, ...d.data() }))
      setFeedCatalog(data)
    })
  }, [])

  return (
    <RouteComponent
      title={title}
      controlls={
        <>
          <Button
            title="Додати запис"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path={`/pages/${id}/add`}
            size={28}
          />
        </>
      }
    >
      {deleteFeed && (
        <DeletePopUp
          title="Видалення запису"
          warningMessage={`Ви впевнені, що хочете видалити запис "${deleteFeed.title}"?`}
          closePopUp={() => setDeleteFeed(null)}
          submitDelete={() => onFeedDelete({ id: deleteFeed.id, docPath: id })}
        />
      )}
      <div className="ToursCatalog">
        {feedCatalog ? (
          isEmpty(feedCatalog) ? (
            <p className="EmptyText">Немає записів</p>
          ) : (
            feedCatalog.map((t, i) => (
              <TourTile
                key={`document-${id}-${i}`}
                {...t}
                onEdit={() => router.history.push(`/pages/${id}/edit/${t.id}`)}
                onDelete={() =>
                  setDeleteFeed({
                    id: t.id,
                    title: t.title,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({ video, title, text, onEdit, onDelete, created }) {
  return (
    <div className="FeedTile" onDoubleClick={onEdit}>
      <div className="Content">
        <InfoLine title="Дата" content={formatDateAsDayMontYearHHMM(created)} />
        <InfoLine title="Текст" content={title} />
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(NaukList)
