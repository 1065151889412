import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './PartnersList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onPartnerDelete from '../PartnersManager/functions/deletePartner'
import { updateDoc } from '../../../utils/db/updateDoc'
import { arrayMove } from 'react-sortable-hoc'
import SortableList from '../../../ui/SortableList/SortableList'

function PartnersList({ ...router }) {
  const [partnersCatalog, setPartnersCatalog] = useState(null)
  const [deletePartner, setDeletePartner] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('partners').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ partnerId: d.id, ...d.data() }))
      setPartnersCatalog(
        orderBy(data, ['pos'], ['asc']).filter((d) => d.isDeleted === false)
      )
    })
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newPartners = arrayMove(partnersCatalog, oldIndex, newIndex)
    setPartnersCatalog(newPartners)
  }

  const updatePartnersOrder = () => {
    setIsLoading(true)
    Promise.all(
      partnersCatalog.map((d, i) =>
        updateDoc({
          path: 'partners',
          docId: d.partnerId,
          data: { pos: i },
        })
      )
    )
      .then(() => setIsLoading(false))
      .catch(() => {
        console.log('error')
      })
  }

  return (
    <RouteComponent
      title="Партнери"
      controlls={
        <>
          <Button
            title="Оновити порядок"
            icon="save"
            fill="accent"
            theme="solid"
            type="button"
            size={28}
            isLoading={isLoading}
            onClick={updatePartnersOrder}
            className="DoctorsList-UpdateSortButton"
          />
          <Button
            title="Додати партнера"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/partners/add"
            size={28}
          />
        </>
      }
    >
      {deletePartner && (
        <DeletePopUp
          title="Видалити партнера"
          warningMessage={`Ви впевнені, що хочете видалити партнера "${deletePartner.title}"?`}
          closePopUp={() => setDeletePartner(null)}
          submitDelete={() =>
            onPartnerDelete({ partnerId: deletePartner.partnerId })
          }
        />
      )}
      <div className="ToursCatalog">
        {partnersCatalog ? (
          isEmpty(partnersCatalog) ? (
            <p className="EmptyText">Немає партнерів</p>
          ) : (
            <SortableList
              className="ToursCatalog-SortableGrid"
              axis="y"
              onSortEnd={onSortEnd}
              pressDelay={200}
              items={partnersCatalog.map((t, i) => (
                <TourTile
                  key={`partner-${i}`}
                  {...t}
                  onEdit={() =>
                    router.history.push(`/partners/edit/${t.partnerId}`)
                  }
                  onDelete={() =>
                    setDeletePartner({
                      title: t.title,
                      partnerId: t.partnerId,
                    })
                  }
                />
              ))}
            />
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({ title, updated, photos, onEdit, onDelete }) {
  return (
    <div className="TourTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        <Img src={find(photos, ['isAvatar', true]).publicUrl} />
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
        {/* <InfoLine
          title="Посл. обновление"
          content={formatDateAsDayMontYearHHMM(updated)}
        /> */}
      </div>
      <div className="Options">
        <div className="OptionButton Gray" onClick={onEdit}>
          <Icon name="expand-arrows-alt" />
        </div>
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(PartnersList)
