import { isEmpty, cloneDeep, find, omit } from 'lodash'
import uploadFiles from '../../../../utils/storage/uploadFiles'
import { updateDoc } from '../../../../utils/db/updateDoc'

const uploadVideo = ({
  form,
  docPath,
  docId,
  fieldProps = ['video'],
  storagePath = 'feeds',
}) => {
  const docsToUpload = []
  const formClone = cloneDeep(form)

  fieldProps.forEach((p) => {
    if (formClone[p].value.needUpload) {
      formClone[p].value.fieldId = p
      docsToUpload.push(formClone[p].value)
      formClone[p].value.needUpload = false
    }
  })

  if (!isEmpty(docsToUpload)) {
    const path = `${storagePath}/`

    return uploadFiles({
      files: docsToUpload,
      storagePath: path,
    }).then((uploadedFiles) => {
      if (!isEmpty(uploadedFiles)) {
        uploadedFiles.forEach((f) => {
          const file = find(docsToUpload, ['fileName', f.fileName])
          if (file) {
            file.publicUrl = f.publicUrl
            file.needUpload = false
            file.storagePath = storagePath
            formClone[file.fieldId].value = file
          }
        })
        return updateDoc({
          path: docPath,
          docId,
          data: {
            video: omit(uploadedFiles[0], [
              'imageUrl',
              'file',
              'needUpload',
              'uploadProgress',
            ]),
          },
        })
      }
    })
  }
  return Promise.resolve('Nothing to upload')
}

export default uploadVideo

// https://firebasestorage.googleapis.com/v0/b/isee-iterra.appspot.com/o/pdf%2Fqrcode.pdf?alt=media
