import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'

class CategoryForm {
  constructor() {
    // this.cityId = {
    //   field: {
    //     fieldId: 'cityId',
    //   },
    //   render: getFieldRenderObject({ isSystem: true }),
    // }
    // this.video = {
    //   field: {
    //     fieldId: 'video',
    //     fieldType: 'inputFile',
    //     inputType: 'file',
    //     label: 'Відео файл',
    //     icon: 'ellipsis-v',
    //   },
    //   render: getFieldRenderObject(),
    // }
    this.isHero = {
      field: {
        fieldId: 'isHero',
        fieldType: 'checkbox',
        label: 'Відобразити на головній',
      },
      render: getFieldRenderObject(),
    }
    this.parentCat = {
      field: {
        fieldId: 'parentCat',
        fieldType: 'select',
        label: 'Материнська категорія',
        getOptions: 'getParentCats',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва категорії',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description = {
      field: {
        fieldId: 'description',
        fieldType: 'texteditor',
        label: 'Опис категорії послуг',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиція',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default CategoryForm
