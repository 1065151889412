import React from 'react'
import { Route } from 'react-router'
import Doctors from '../../components/Doctors/index'
import Price from '../../components/Price/Price'
import News from '../../components/News/News'
import Partners from '../../components/Partners'
import Feeds from '../../components/Feeds/index'
import Docs from '../../components/Docs/Docs'
import Pages from '../../components/Pages/Pages'
import Slider from '../../components/Slider/Slider'

function Routes() {
  return (
    <div className="Routes">
      <Route path="/slider" component={Slider} />
      <Route path="/price" component={Price} />
      <Route path="/doctors" component={Doctors} />
      <Route path="/news" component={News} />
      <Route path="/partners" component={Partners} />
      <Route path="/feeds" component={Feeds} />
      <Route path="/pages" component={Pages} />
      <Route path="/pdf" component={Docs} />
      {/* <Route path="/faq" component={Faq} />
      <Route path="/feeds" component={Feeds} />
      <Route path="/partners" component={Partners} />
      <Route path="/settings" component={Settings} />
      <Route path="/qrcode" component={Qrcode} /> */}
    </div>
  )
}

export default Routes
