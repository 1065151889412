import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './InstrumentsList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onFeedDelete from '../InstrumentsManager/functions/deleteFeed'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'
import parse from 'html-react-parser'

function InstrumentsList({ ...router }) {
  const [feedCatalog, setFeedCatalog] = useState(null)
  const [deleteFeed, setDeleteFeed] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('instruments').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ instrumentId: d.id, ...d.data() }))
      setFeedCatalog(orderBy(data, ['pos'], ['asc']))
    })
  }, [])

  return (
    <RouteComponent
      title="Обладнання"
      controlls={
        <>
          <Button
            title="Додати позицію"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/pages/instruments/add"
            size={28}
          />
        </>
      }
    >
      {deleteFeed && (
        <DeletePopUp
          title="Видалення позиції"
          warningMessage={`Ви впевнені, що хочете видалити позицію "${deleteFeed.title}"?`}
          closePopUp={() => setDeleteFeed(null)}
          submitDelete={() =>
            onFeedDelete({ instrumentId: deleteFeed.instrumentId })
          }
        />
      )}
      <div className="ToursCatalog">
        {feedCatalog ? (
          isEmpty(feedCatalog) ? (
            <p className="EmptyText">Немає позицій</p>
          ) : (
            feedCatalog.map((t, i) => (
              <TourTile
                key={`nauk-${i}`}
                {...t}
                onEdit={() =>
                  router.history.push(
                    `/pages/instruments/edit/${t.instrumentId}`
                  )
                }
                onDelete={() =>
                  setDeleteFeed({
                    title: t.title,
                    instrumentId: t.instrumentId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({
  photos,
  title,
  descriptionShort,
  onEdit,
  onDelete,
  created,
}) {
  return (
    <div className="InstrumentTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        {!isEmpty(photos) ? (
          <Img src={find(photos, ['isAvatar', true]).publicUrl} />
        ) : (
          <div className="DoctorAvatar">
            <Icon name="tools" />
          </div>
        )}
      </div>
      <div className="Content">
        <InfoLine title="Назва апарату" content={title} />
        <InfoLine title="Короткий опис" content={descriptionShort} />
        {/* <InfoLine title="Дата" content={formatDateAsDayMontYearHHMM(created)} /> */}
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(InstrumentsList)
