import React, { useEffect, useState } from 'react'
import './PartnersManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { isEmpty, omit, pick } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'
import usePrevious from '../../../hooks/usePrevious'

function PartnersManager({ ...router }) {
  const partnerId = router.match.params.id
  const [form, setForm] = useState(
    !partnerId ? createForm({ formPattern: new PartnerForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (partnerId) {
      getDoc({ path: 'partners', docId: partnerId }).then((data) =>
        setForm(
          createForm({
            formPattern: new PartnerForm(),
            formData: data,
          })
        )
      )
    }
  }, [partnerId])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }

    if (isEmpty(form.photos.values)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, завантажте логотип партнера',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = partnerId
      ? updateDoc({ path: 'partners', docId: partnerId, data: values })
      : addDoc({ path: 'partners', data: values })

    opRef.then((docId) =>
      uploadPhotos({ photos: data.photos, docId })
        .then(() => {
          router.history.push(`/partners/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: FeedsManager.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent
      title={partnerId ? 'Редагувати партнера' : 'Додати нового партера'}
    >
      <div className="PartnersManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <p className="Title">Основна інформація</p>
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['title', 'link'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <p className="Title">Логотип</p>
        <PhotoManager
          projectId={partnerId}
          projectPath="partners"
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
        <div className="Buttons">
          <Button
            title="Зберегти"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрити"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/partners/')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class PartnerForm {
  constructor() {
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва партнера',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.link = {
      field: {
        fieldId: 'link',
        fieldType: 'input',
        inputType: 'text',
        label: 'Посилання на сайт',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.pos = {
      field: { fieldId: 'pos', value: 0 },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: { fieldId: 'isDeleted', value: false },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(PartnersManager)
