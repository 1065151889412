import React, { useEffect, useState } from 'react'
import { createForm } from '../../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../../utils/newforms/render/FormRender'
import StatusMessage, {
  useStatusMessage,
} from '../../../../ui/StatusMessage/StatusMessage'
import Button from '../../../../ui/Button/Button'
import isFormValid from '../../../../utils/newforms/validation/isFormValid'
import './InfoPage.sass'
import FieldRender from '../../../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../../../utils/files/fileToBase64'
import getDoc from '../../../../utils/db/getDoc'
import { isEmpty, omit } from 'lodash'
import getFormValues from '../../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../../utils/db/updateDoc'
import uploadVideo from './uploadVideo'

function InfoPage({ docId, title }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ path: 'pages', docId }).then((data) => {
      setForm(
        createForm({
          formPattern: new InfoPageForm(),
          formData: data,
        })
      )
    })
  }, [])

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }

    // // if (isEmpty(form.photos.values)) {
    // //   window.scrollTo({ top: 0, behavior: 'smooth' })
    // //   return setStatusMessage({
    // //     show: true,
    // //     type: 'fail',
    // //     message: 'Пожалуйста, добавъте фото врача',
    // //     closeAfter: 5000,
    // //   })
    // // }

    setIsLoading(true)

    uploadVideo({ form, setForm, docPath: 'pages', docId })
      .then(() => {
        const data = getFormValues({ form })
        const values = omit(data, ['video'])
        updateDoc({ path: 'pages', docId, data: values })
      })
      .then(() => {
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Дані збережені',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <div className="InfoPage">
      <h5>{title}</h5>
      {statusMessage.show && (
        <StatusMessage
          className="Site-StatusMessage"
          type={statusMessage.type}
          message={statusMessage.message}
        />
      )}
      {!isEmpty(form) && (
        <>
          <FieldRender
            field={form.video}
            form={form}
            setForm={(newForm) => onFileAdded(newForm, 'video')}
            showErrors={showErrors}
          />
          <FormRender
            sections={[
              {
                fields: ['text'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </>
      )}
      <div className="Buttons">
        <Button
          title="Зберегти"
          theme="solid"
          fill="accent"
          iconPosition="right"
          size={48}
          isLoading={isLoading}
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

class InfoPageForm {
  constructor() {
    this.video = {
      field: {
        fieldId: 'video',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Відео файл',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        inputType: 'text',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default InfoPage
