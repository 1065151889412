import { isEmpty, cloneDeep, find, omit } from 'lodash'
import uploadFiles from '../../../../utils/storage/uploadFiles'
import { updateDoc } from '../../../../utils/db/updateDoc'

const uploadPhotos = ({
  photos,
  docId,
  docPath = 'instruments',
  storagePath = 'photos',
}) => {
  const photosToUpload = photos.filter((p) => p.needUpload)
  if (!isEmpty(photosToUpload)) {
    const path = `${storagePath}/${docId}`

    return uploadFiles({ files: photosToUpload, storagePath: path }).then(
      (uploadedPhotos) => {
        if (!isEmpty(uploadedPhotos)) {
          const photosClone = cloneDeep(photos)
          uploadedPhotos.forEach((p) => {
            const photo = find(photosClone, ['fileName', p.fileName])
            if (photo) {
              photo.publicUrl = p.publicUrl
              photo.needUpload = false
              photo.storagePath = storagePath
            }
          })

          return updateDoc({
            path: docPath,
            docId,
            data: {
              photos: photosClone.map((p) =>
                omit(p, ['imageUrl', 'file', 'needUpload', 'uploadProgress'])
              ),
            },
          })
        }
      }
    )
  }
  return Promise.resolve('Nothing to upload')
}

export default uploadPhotos
