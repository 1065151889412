import React from 'react'
import { NavLink } from 'react-router-dom'
import './PagesNav.sass'

function PagesNav({ pages }) {
  return (
    <div className="PagesNav">
      {pages.map((p) => (
        <NavLink
          key={p.id}
          to={p.path}
          className="PageNavElement"
          activeClassName="PageNavElement-Active"
        >
          {p.title}
        </NavLink>
      ))}
    </div>
  )
}

export default PagesNav
