import { merge, isString, isUndefined, isEmpty, cloneDeep } from 'lodash'
import {
  createSelectbox,
  createField,
  createCheckbox,
} from './createFormElement'

export const createForm = ({ formData = {}, formPattern, methods }) => {
  const form = {}

  Object.entries(formPattern).forEach(([k, v]) => {
    if (v.render.isSystem) {
      form[k] = createSystemFieldConfig({ config: v, values: formData[k] })
    } else {
      form[k] = createFieldConfig(v.field, methods, formData[k])
    }
    merge(form[k], { render: v.render })
  })

  return form
}

const createFieldConfig = (fieldConfig, methods, value) => {
  let config = cloneDeep(fieldConfig)

  if (!isUndefined(value)) config.value = value

  switch (config.fieldType) {
    case 'select':
      if (isString(config.getOptions)) {
        config = { ...config, getOptions: methods[config.getOptions] }
      }
      return createSelectbox(config)
    case 'input':
    case 'inputFile':
    case 'textarea-autosize':
    case 'textarea':
    case 'texteditor':
    case 'phone':
    case 'address':
    case 'dateRange':
      return createField(config)
    case 'fieldset':
      return {
        isFieldset: true,
        required: config.required,
        label: config.label,
        values: isEmpty(value)
          ? [
              config.fields.map((f) => ({
                ...createFieldConfig(f.field, methods),
                render: f.render,
              })),
            ]
          : value.map((v) =>
              config.fields.map((f) => ({
                ...createFieldConfig(f.field, methods, v[f.field.fieldId]),
                render: f.render,
              }))
            ),
        newValue: config.fields.map((f) => ({
          ...createFieldConfig(f.field, methods),
          render: f.render,
        })),
      }
    case 'checkbox':
      return createCheckbox(config)
    default:
      break
  }
}

const createSystemFieldConfig = ({ config, values }) => ({
  fieldId: config.field.fieldId,
  values: !isUndefined(values) ? values : config.field.value,
  required: config.field.required,
  label: config.field.label,
  requiredProp: config.field.requiredProp,
})
