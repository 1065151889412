import { DataGrid, GridColumnMenu, GridRowModes } from '@mui/x-data-grid'
import React, { useState } from 'react'
import tableStyles from '../../../styles/table'
import './CategoryPriceTile.sass'
import Icon from '../../../ui/Icon/Icon'
import Spinner from '../../../ui/Spinner/Spinner'
import { db } from '../../../config/firebase'
import { updateDoc } from '../../../utils/db/updateDoc'
import Button from '../../../ui/Button/Button'

// import {
//   addButton,
//   deleteButton,
//   editButton,
// } from '../../../../../../../components/UI/SceneContent/SceneContentHeader/buttons'
// import SceneTable from '../../../../../../../components/UI/SceneContent/SceneTable/SceneTable'

// import {
//   editButton as editOption,
//   deleteButton as deleteOption,
// } from '../../../../../../../components/UI/SceneContent/Buttons/buttons'

function CategoryPriceTile({ services, setServices, categoryData, setPopUp }) {
  const [rowOnSave, setRowOnSave] = useState({ id: null, status: null })
  const [rowModesModel, setRowModesModel] = useState({})

  const columns = [
    {
      field: 'pos',
      headerName: '#',
      headerClassName: 'TableHeadCol',
      type: 'number',
      minWidth: 50,
      editable: true,
    },
    {
      field: 'title',
      headerName: 'Послуга',
      headerClassName: 'TableHeadCol',
      minWidth: 250,
      flex: 1,
      editable: true,
    },
    {
      field: 'duration',
      headerName: 'Тривалість',
      headerClassName: 'TableHeadCol',
      width: 200,
      editable: true,
    },
    {
      field: 'price',
      headerName: 'Вартість',
      headerClassName: 'TableHeadCol',
      width: 200,
      type: 'number',
      editable: true,
    },

    {
      field: 'actions',
      headerClassName: 'TableHeadCol',
      headerName: 'Дії',
      width: 100,
      renderCell: (params) => (
        <TableActions
          {...{
            params,
            rowOnSave,
            rowModesModel,
            setRowModesModel,
            deleteService,
          }}
        />
      ),
    },
  ]

  const onRowUpdateSubmit = (newRow) => {
    setRowOnSave({ id: newRow.id, status: 'saving' })
    const updatedRow = { ...newRow, isNew: false }
    const newServices = services.map((row) => {
      return row.serviceId === newRow.serviceId ? updatedRow : row
    })
    setServices(newServices)
    updateDoc({
      path: `settings/price/services`,
      docId: updatedRow.serviceId,
      data: updatedRow,
    }).then(() => {
      setRowOnSave({ id: newRow.id, status: 'success' })
      setTimeout(() => setRowOnSave({ id: null, status: null }), 2000)
    })
    return updatedRow
  }

  const deleteService = (serviceId) => {
    const newServices = services.filter((s) => s.serviceId !== serviceId)
    updateDoc({
      path: 'settings/price/services',
      docId: serviceId,
      data: { isDeleted: true },
    }).then(() => {
      setServices(newServices)
    })
  }

  return (
    <div className="CategoryPriceTile">
      <p className="Title">
        [{categoryData.pos}] - {categoryData.title}
        <div className="CategoryPriceTile-Buttons">
          <Button
            icon="pencil-alt"
            fill="normal"
            theme="bounded"
            onClick={() =>
              setPopUp({ type: 'cat', instanceId: categoryData.categoryId })
            }
            size={28}
          />
          <Button
            icon="trash-alt"
            fill="normal"
            theme="bounded"
            onClick={() =>
              setPopUp({ type: 'delCat', instanceId: categoryData.categoryId })
            }
            size={28}
          />
        </div>
        <Button
          icon="plus"
          fill="accent"
          theme="solid"
          className="AddCategoryButton"
          onClick={() =>
            setPopUp({ type: 'service', instanceId: categoryData.categoryId })
          }
          size={28}
        />
      </p>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'pos', sort: 'asc' }],
          },
        }}
        editMode="row"
        sx={tableStyles}
        slots={{ columnMenu: CustomColumnMenu }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(model) => setRowModesModel(model)}
        onRowEditStart={(params, event) => (event.defaultMuiPrevented = true)}
        onRowEditStop={(params, event) => (event.defaultMuiPrevented = true)}
        rows={services.filter((s) => s.categoryId === categoryData.categoryId)}
        columns={columns}
        getRowId={(row) => row.serviceId}
        rowsPerPageOptions={[25, 50, 100]}
        // pageSize={pageSize}
        processRowUpdate={onRowUpdateSubmit}
        onProcessRowUpdateError={(e) => {
          console.log('🚀 ~ file: ToolsTable.jsx:172 ~ ToolsTable ~ e:', e)
        }}
        autoHeight
      />
    </div>
    // <SceneContent
    //   title={title}
    //   buttons={[
    //     deleteButton({ clicked: () => togglePopUp({ type: 'delCat', instanceId: categoryId }) }),
    //     editButton({ clicked: () => togglePopUp({ type: 'cat', instanceId: categoryId }) }),
    //     addButton({ clicked: () => togglePopUp({ type: 'service', instanceId: categoryId }) }),
    //   ]}
    // >
    //   <SceneTable
    //     header={[['Услуга'], ['Стоимость'], ['', 32]]}
    //     body={
    //       !isEmpty(services) &&
    //       filter(services, ['categoryId', categoryId]).map((s) => [
    //         { id: s.serviceId },
    //         <span>{s.title}</span>,
    //         <span>{s.price}</span>,
    //         'options',
    //       ])
    //     }
    //     options={[
    //       editOption((serviceId) => togglePopUp({ type: 'service', instanceId: categoryId, serviceId })),
    //       deleteOption((serviceId) => togglePopUp({ type: 'delServ', serviceId })),
    //     ]}
    //   />
    // </SceneContent>
  )
}

const TableActions = ({
  params,
  rowOnSave,
  rowModesModel,
  setRowModesModel,
  deleteService,
}) => {
  const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

  return (
    <div className="TableActions">
      {isInEditMode ? (
        <div className="TableActions-FrontActions">
          <div className="TableActions-IconContainer">
            <Icon
              name="save"
              onClick={() =>
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: {
                    mode: GridRowModes.View,
                  },
                })
              }
            />
          </div>
          <div className="TableActions-IconContainer">
            <Icon
              name="times"
              onClick={() =>
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: {
                    mode: GridRowModes.View,
                    ignoreModifications: true,
                  },
                })
              }
            />
          </div>
        </div>
      ) : rowOnSave.id === params.id && rowOnSave.status === 'saving' ? (
        <div className="TableActions-IconContainer">
          <Spinner type="button" theme="gray" />
        </div>
      ) : rowOnSave.id === params.id && rowOnSave.status === 'success' ? (
        <div className="TableActions-IconContainer TableActions-IconContainer_theme_success">
          <Icon name="check-circle" weight="solid" />
        </div>
      ) : (
        <div className="TableActions-FrontActions">
          <div className="TableActions-IconContainer">
            <Icon
              name="pencil-alt"
              onClick={() =>
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: { mode: GridRowModes.Edit },
                })
              }
            />
          </div>
          <div className="TableActions-IconContainer">
            <Icon name="trash-alt" onClick={() => deleteService(params.id)} />
          </div>
        </div>
      )}
    </div>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      components={{
        ColumnMenuColumnsItem: null,
      }}
    />
  )
}

export default CategoryPriceTile
