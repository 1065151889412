import React from 'react'
import RouteComponent from '../RouteComponent/RouteComponent'
import PagesNav from './components/PagesNav'
import InfoPage from './components/InfoPage/InfoPage'
import { Route } from 'react-router-dom'
import './Pages.sass'
import Instruments from '../Instruments/'
import Smi from '../Smi/'
import Nauk from '../Nauk/'
import Contacts from '../Contacts/Contacts'

function Pages() {
  const pages = [
    {
      id: 'instruments',
      path: '/pages/instruments',
      title: 'Обладнання',
      component: <Instruments />,
    },
    {
      id: 'about',
      path: '/pages/about',
      title: 'Про клініку',
      component: <InfoPage title="Про клініку" docId="about" />,
    },
    {
      id: 'purpose',
      path: '/pages/purpose',
      title: 'Пропозиція',
      component: <InfoPage title="Пропозиція" docId="purpose" />,
    },
    {
      id: 'nauk',
      path: '/pages/nauk',
      title: 'Наукова діяльність',
      component: <InfoPage title="Наукова діяльність" docId="nauk" />,
    },
    {
      id: 'traning',
      path: '/pages/traning',
      title: 'Тренінги',
      component: <Nauk id="traning" title="Тренінги" />,
    },
    {
      id: 'konf',
      path: '/pages/konf',
      title: 'Наукові конференції',
      component: <Nauk id="konf" title="Наукові конференції" />,
    },
    {
      id: 'inshe',
      path: '/pages/inshe',
      title: 'Інші програми',
      component: <Nauk id="inshe" title="Інші програми" />,
    },
  ]

  return (
    <RouteComponent title={'Сторінки'}>
      <div className="PageContainer">
        <PagesNav pages={pages} />

        {pages.map((p) => (
          <Route
            key={`page-${p.id}`}
            path={p.path}
            render={() => p.component}
          />
        ))}
      </div>
    </RouteComponent>
  )
}

export default Pages
