import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NaukList from './NaukList/NaukList'
import NaukManager from './NaukManager/NaukManager'

function Routes({ id, title }) {
  return (
    <>
      <Route
        path={`/pages/${id}`}
        exact
        render={() => <NaukList id={id} title={title} />}
      />
      <Route
        path={`/pages/${id}/add`}
        exact
        render={() => <NaukManager id={id} />}
      />
      <Route
        path={`/pages/${id}/edit/:id`}
        exact
        render={() => <NaukManager id={id} />}
      />
    </>
  )
}

export default Routes
