import React, { useEffect, createContext, useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import Auth from './pages/Auth/Auth'
import Home from './pages/Home/Home'
import getDoc from './utils/db/getDoc'
import getCollection from './utils/db/getCollection'

export const AuthContext = createContext({ user: null })

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [cities, setCities] = useState(null)

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    getCollection({ path: 'cities', docIdName: 'id' }).then((docs) =>
      setCities(docs)
    )
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, cities }}>
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Route path="/" component={Auth} />
    </AuthProvider>
  )
}

export default App
