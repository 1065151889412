import React from 'react'
import './Nav.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../../../ui/Icon/Icon'
import { firebase } from '../../../config/firebase'

function Nav() {
  return (
    <div className="Nav">
      <div className="NavBody">
        <NavElement title="Слайдер" icon="image" link="/slider" />
        <NavElement title="Прайс" icon="list" link="/price" />
        <NavElement title="Лікарі" icon="user-md" link="/doctors" />
        {/* <NavElement title="Відгуки" icon="comment" link="/feeds" /> */}
        <NavElement title="Партн." icon="handshake-alt" link="/partners" />
        <NavElement title="Сторінки" icon="file-alt" link="/pages" />
        <NavElement title="PDF" icon="file-pdf" link="/pdf" />
        {/* <NavElement title="Новости" icon="newspaper" link="/news" /> */}
        {/* <NavElement title="ЧаВо" icon="question" link="/faq" /> */}
        {/* <NavElement title="Другое" icon="cog" link="/settings" />
        <NavElement title="QR code" icon="qrcode" link="/qrcode" /> */}
      </div>

      <NavElement
        title="Вихід"
        icon="sign-out-alt"
        onClick={() => firebase.auth().signOut()}
      />
    </div>
  )
}

function NavElement({ title, icon, link, onClick }) {
  const content = (
    <>
      <div className="NavElement-Icon">
        <Icon name={icon} />
      </div>
      <p className="Title">{title}</p>
    </>
  )
  return onClick ? (
    <div className="NavElement" onClick={onClick}>
      {content}
    </div>
  ) : (
    <NavLink
      to={link}
      className="NavElement"
      activeClassName="NavElement-Active"
    >
      {content}
    </NavLink>
  )
}

export default Nav
