import React, { useEffect, useState } from 'react'
import './InstrumentsManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { isEmpty, omit, pick } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'

function FeedsManager({ ...router }) {
  const feedId = router.match.params.id
  const [form, setForm] = useState(
    !feedId ? createForm({ formPattern: new FeedForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (feedId) {
      getDoc({ path: 'instruments', docId: feedId }).then((data) =>
        setForm(
          createForm({
            formPattern: new FeedForm(),
            formData: data,
          })
        )
      )
    }
  }, [feedId])

  // const onFileAdded = (newForm, fieldId) => {
  //   fileToBase64(newForm[fieldId].value.file).then((converted) => {
  //     newForm[fieldId].value.fileUrl = converted
  //     setForm(newForm)
  //   })
  // }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })
    const values = {
      ...omit(data, ['video']),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = feedId
      ? updateDoc({ path: 'instruments', docId: feedId, data: values })
      : addDoc({ path: 'instruments', data: values })

    opRef
      .then((docId) =>
        uploadPhotos({ photos: data.photos, docId }).then(() => {
          router.history.push(`/pages/instruments/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Дані збережено',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
      )
      .catch((error) => {
        console.log(
          '🚀 ~ file: FeedsManager.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent
      title={feedId ? 'Редагувати позицію' : 'Додати нову позицію'}
    >
      <div className="FeedsManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['pos', 'title', 'descriptionShort', 'descriptionLong'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <PhotoManager
            projectId={feedId}
            formData={form ? form.photos.values : null}
            syncState={(moduleState) =>
              syncForm({
                propIdentifier: 'photos',
                form,
                setForm,
                newForm: moduleState,
              })
            }
          />
        </div>

        <div className="Buttons">
          <Button
            title="Зберегти"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрити"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/pages/instruments')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class FeedForm {
  constructor() {
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва апарату',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.descriptionShort = {
      field: {
        fieldId: 'descriptionShort',
        fieldType: 'textarea',
        label: 'Короткий опис',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.descriptionLong = {
      field: {
        fieldId: 'descriptionLong',
        fieldType: 'textarea',
        label: 'Повний опис опис',
        required: true,
      },
      render: getFieldRenderObject(),
    }

    // this.video = {
    //   field: {
    //     fieldId: 'video',
    //     fieldType: 'inputFile',
    //     inputType: 'file',
    //     label: 'Відео файл',
    //     icon: 'ellipsis-v',
    //   },
    //   render: getFieldRenderObject(),
    // }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиція у cписку',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(FeedsManager)
