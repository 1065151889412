import React, { useState, useEffect } from 'react'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../utils/newforms/createForm'
import getDoc from '../../../utils/db/getDoc'
import FormRender from '../../../utils/newforms/render/FormRender'
import PopUp from '../../../ui/PopUp/PopUp'
import CategoryForm from './categoryObject'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import { concat, isEmpty, omit, toNumber } from 'lodash'
import './CategoryManager.sass'
import FieldRender from '../../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../../utils/files/fileToBase64'
import uploadVideo from './uploadVideo'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import uploadPhotos from './uploadPhotos'
import getCollection from '../../../utils/db/getCollection'

function CategoryManager({ activeCityId, categoryId, onClose }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    getDoc({
      path: `settings/price/categories`,
      docId: categoryId,
      docIdName: 'categoryId',
    }).then((data) => {
      console.log(data)
      setForm(
        createForm({
          formData: data
            ? { ...data, pos: `${data.pos}` }
            : { cityId: activeCityId },
          formPattern: new CategoryForm(),
          methods: {
            getParentCats: () =>
              getCollection({
                path: 'settings/price/categories',
                docIdName: 'categoryId',
              }).then((res) => {
                const arr = [{ value: null, label: 'Немає категорії' }]
                const options = res
                  .filter((r) => !r.isDeleted)
                  .filter((r) =>
                    categoryId ? r.categoryId !== categoryId : true
                  )
                  .map((r) => ({ label: r.title, value: r.categoryId }))
                return concat(arr, options)
              }),
          },
        })
      )
    })
  }, [])

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля',
        closeAfter: 3000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })
    const values = {
      ...omit(data, ['video']),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
      pos: toNumber(data.pos),
    }

    let dbFunc
    if (categoryId) {
      dbFunc = updateDoc({
        path: `settings/price/categories`,
        docId: categoryId,
        data: values,
      })
    } else {
      dbFunc = addDoc({ path: `settings/price/categories`, data: values })
    }
    dbFunc
      .then(
        (docId) =>
          uploadPhotos({ photos: data.photos, docId }).then(() =>
            window.location.reload()
          )
        // uploadVideo({
        //   form,
        //   setForm,
        //   docPath: 'settings/price/categories',
        //   docId,
        // })
      )
      .catch((error) => {
        console.log(
          '🚀 ~ file: CategoryManager.jsx:74 ~ onSubmit ~ error:',
          error
        )
        // logError({ error })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PopUp
      show
      close={onClose}
      title={categoryId ? 'Редагувати категорію' : 'Створити категорію'}
      submitButtons
      onSubmit={onSubmit}
      onCancel={onClose}
      isLoading={isLoading}
      className="CategoryPopUp"
    >
      <div className="CategoryPrice-PopUp-Container">
        {statusMessage.show && (
          <StatusMessage
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[
            { fields: ['isHero', 'parentCat', 'title', 'description', 'pos'] },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        {/* {!isEmpty(form) && (
          <FieldRender
            field={form.video}
            form={form}
            setForm={(newForm) => onFileAdded(newForm, 'video')}
            showErrors={showErrors}
          />
        )} */}
        <PhotoManager
          projectId={categoryId}
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
      </div>
    </PopUp>
  )
}

export default CategoryManager
