import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'

class ServiceForm {
  constructor() {
    this.categoryId = {
      field: { fieldId: 'categoryId' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва послуги',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'input',
        inputType: 'text',
        label: 'Вартість',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.duration = {
      field: {
        fieldId: 'duration',
        fieldType: 'input',
        inputType: 'text',
        label: 'Тривалість',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиція',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default ServiceForm
